@import '../../styles/shared';

body {
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 300;
	color: #666666;
	font-size: 18px;
}

.bg-f6f6f3 {
	background-color: #f6f6f3;
}

h1, h2, h3, h4, h5 {
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 400;
	color: #2e2d29;
}

h1 {
	font-size: 48px;
	font-weight: 300;
}

h2 {
	font-size: 30px;
}

h3 {
	font-size: 26px;
}

p {
	font-size: 18px;
	font-weight: 400;
}

.btn {
	padding: 0px 20px;
	line-height: 44px;
	border-radius: 30px;
	text-transform: uppercase;
	border: none;
	font-weight: 400;
}

.btn-default {
	background-color: #e0e1e2;
}

btn-default:hover {
	background-color: #cacbcd;
}

.btn-primary, .btn-primary:hover {
	background-color: #0065cb;
}

.header {
	
}

.top-menu {
	padding: 40px 0;
}

.top-menu .h2 {
	margin: 0;
}

.top-menu ul {
	text-align: right;
	margin: 0;
}

.top-menu ul li:last-child {
	padding-left: 20px;
}

.top-menu a {
	color: #444;
	line-height: 50px;
}

.top-menu .btn {
	border: 1px solid #eee;
}

.header-bar {
	padding-top: 150px;
	background-image: url('../../assets/images/cars.jpg');
}

.bar-wrapper {
	height: 50px;
}

.green-90 {
	height: 50px;
	background-color: rgba(0, 155, 118, 0.9)
}

.margin-bottom-20 {
	margin-bottom: 20px;
}

.margin-bottom-40 {
	margin-bottom: 40px;
}

.footer {
	padding: 40px 0;
}

.footer .list-inline {
	margin-bottom: 20px;
}

.footer .list-inline>li {
	padding-right: 40px;
}

.menu-left {
	background-color: #175e54;
}

.menu-left ul {
	padding: 0 0 30px 0;
}

.menu-left ul li {
	list-style: none;
	line-height: 1.5;
}

.menu-left ul li a {
	font-size: 18px;
	padding: 5px 40px;
	color: #ffffff;
	display: block;
}

.menu-left ul li.active a {
	cursor: default;
	background-color: rgba(0, 0, 0, 0.17);
}

.menu-title {
	font-size: 24px;
	padding: 40px 40px 20px 40px;
	color: #ffffff;
	line-height: 25px;	
}
.pagine-inner {
	padding: 100px 0;
}
.ui.container {
	padding-right: 15px!important;
	padding-left: 15px!important;
	margin: 0 auto!important;
}

.home-howto {
	padding: 110px 80px;
}

.home-rates {
	padding: 0 80px 110px 80px;
}

@media only screen and (max-width: 991px) {
	body {
		font-weight: 400;
	}
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin: 7.5px -15px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    /* since 3.1.0 */
    .navbar-collapse.collapse.in { 
        display: block!important;
    }
    .collapsing {
        overflow: hidden!important;
    }
	h1 {
		font-size: 40px;
	}
	.home-howto {
		padding: 80px 30px;
	}
	.home-rates {
		padding: 0 30px 80px 30px;
	}
	.top-menu {
		padding: 20px 0;
	}
	.top-menu .col-md-pull-6 {
		padding-top: 40px;
	}
	.top-menu .h2 {
		font-size: 24px;
	}
	.top-menu ul {
		text-align: center;
		margin: 0;
	}
	.top-menu ul img {
		height: 30px;
	}
	.ui.steps .step {
		display: none;
	}
	.ui.steps .step.active {
		display: block!important;
	}
}

.home-cta {
	font-size: 26px;
	font-weight: 300;
}

.padding-40 {
	padding: 40px;
}

.navbar {
	margin-bottom: 0px;
	background-color: #fff;
	border: none;
}

.navbar-nav>li>a {
	padding: 20px 15px;
}

.partner-logo {
	height: 50px;
}

.parking-item {
	margin-bottom: 40px;
}

.btn-gohome {
	margin-top: 40px;
}

.ui.button {
	padding: 0px 20px;
	line-height: 44px;
	border-radius: 30px;
	text-transform: uppercase;
	border: none;
	font-size: 18px;
	font-weight: 400;
	background-color: #cacbcd;
}

.ui.button:hover {
	background-color: #cacbcd;
}

.clndr-calendars-segment .ui.button {
	line-height: 1;
	font-size: 14px;
}

.ui.menu.top-menu {
	border: none;
	top: 0;
	z-index: 10;
	margin: 0;
	background: $primary-color;

	h2 {
		color: $title-color-light;
	}
}

.ui.modal {
	top: auto;
	bottom: auto;
	left: auto;
	right: auto;
}





